
import Vue from 'vue';
import Component from 'vue-class-component';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import SearchTerritories from '@/general/infrastructure/shared/SearchTerritories.vue';
import { TypeIdentificationFindAll } from '@/settings/application/uses_cases/parmsGeneral/typeIdentificacion/search/TypeIdentificationFindAll';
import { WarehouseFindAll } from '@/settings/application/uses_cases/warehouse/search/WarehouseFindAll';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { CreateEntity } from '@/settings/application/uses_cases/entity/create/CreateEntity';
import { CompanyFindById } from '@/settings/application/uses_cases/company/search/CompanyFindById';
import { ValidationObserver } from 'vee-validate/dist/types/components';
import { CreateUser } from '@/settings/application/uses_cases/user/create/CreateUser';
import { User } from '@/settings/domain/user/User';
import { Role } from '@/settings/domain/role/Role';
import { RoleFindById } from '@/settings/application/uses_cases/role/search/RoleFindById';
import { FileFindAll } from '@/settings/application/uses_cases/file/search/FileFindAll';
import { Country } from '@/settings/domain/country/Country';
import { CountryFindByDescriptionLikeInGeneralTerritories } from '@/settings/application/uses_cases/country/search/CountryFindByDescriptionLikeInGeneralTerritories';
import { SearchTerritoryByCountryAndDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByCountryAndDescription';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { CheckUserExistence } from '@/settings/application/uses_cases/user/search/CheckUserExistence';
import { RoleFindByIdNoUsers } from '@/settings/application/uses_cases/role/search/RoleFindByIdNoUsers';

@Component({
  name: 'CustomerRegistration',
  components: {
    SearchTerritories
  }
})
export default class CustomerRegistration extends Vue {
  @Inject(TYPES.FINDALL_TYPE_IDENTIFICATION)
  readonly typeIdentificationFindAll!: TypeIdentificationFindAll;
  @Inject(TYPES.FINDALL_WAREHOUSE)
  readonly warehouseFindAll!: WarehouseFindAll;
  @Inject(TYPES.API_ENTITY_SAVE)
  readonly createEntity!: CreateEntity;
  @Inject(TYPES.FINDBYID_COMPANY)
  readonly companyFindById!: CompanyFindById;
  @Inject(TYPES.CREATE_USER)
  readonly createUser!: CreateUser;
  @Inject(TYPES.FINDBYMENU_ROLE)
  readonly roleFindById!: RoleFindById;
  @Inject(TYPES.ROLE_FIND_BY_ID_NO_USERS)
  readonly findRoleByIdNoUsers!: RoleFindByIdNoUsers;
  @Inject(TYPES.FINDALL_FILE)
  readonly fileFindAll!: FileFindAll;
  @Inject(TYPES.COUNTRY_FIND_BY_DESCRIPTION_IN_GENERAL_TERRITORIES)
  readonly findCountryInGeneralTerritories!: CountryFindByDescriptionLikeInGeneralTerritories;
  @Inject(TYPES.API_FIND_TERRITORIES_BY_COUNTRY_AND_DESCRIPTION)
  readonly findLocationByCountryAndDescription!: SearchTerritoryByCountryAndDescription;
  @Inject(TYPES.CHECK_USER_EXISTENCE)
  readonly checkUserExistence!: CheckUserExistence;

  formEntity: Entity = new Entity();
  payloadUser: User = new User();
  payloadRole: Role = new Role();
  countryList: Country[] = [];
  territories: Territories[] = [];
  isLoading = false;
  name = '';
  lastname = '';
  typeDocumentList = [];
  checkTerms = false;
  poBox = '';
  poBoxList = [];
  termsConditions = '';
  fileName = '';
  timeoutFunction: number | undefined;
  dataCompany = JSON.parse(localStorage.getItem('companyData') as string);
  showUserExistence = false;

  $refs!: {
    form_registration: InstanceType<typeof ValidationObserver>;
  };

  goBack() {
    this.$router.push('/customer/login');
  }

  created() {
    this.fillParams();
  }

  fillParams() {
    this.findTypeIdentification();
    this.findWareHouses();
    if (this.dataCompany) this.termsConditionsCompany();
    this.findRole();
  }

  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  poBoxLabel(warehouse: Warehouse) {
    return `${warehouse.description} - ${warehouse.address}`;
  }

  timeoutExecutionOfGetCountry(input: string) {
    clearTimeout(this.timeoutFunction);
    this.timeoutFunction = setTimeout(async () => {
      input.length > 2 && (await this.getCountry(input));
      clearTimeout(this.timeoutFunction);
    }, 600);
  }

  timeoutExecutionOfGetCity(input: string) {
    clearTimeout(this.timeoutFunction);
    this.timeoutFunction = setTimeout(async () => {
      this.formEntity.country !== null &&
        input.length > 2 &&
        (await this.getCitiesByCountryAndDescription(input, this.formEntity.country));
      clearTimeout(this.timeoutFunction);
    }, 600);
  }

  async getCountry(input: string) {
    try {
      this.isLoading = true;
      const res = await this.findCountryInGeneralTerritories.execute(input);
      this.countryList = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async getCitiesByCountryAndDescription(input: string, country: Country) {
    try {
      this.isLoading = true;
      const payloadCity: any = {
        country: country.code,
        query: input
      };

      const res = await this.findLocationByCountryAndDescription.execute(payloadCity);
      this.territories = res.length > 0 ? res : [];
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async termsConditionsCompany() {
    const urlPdf = this.dataCompany.urlPdf;
    this.isLoading = true;

    if (urlPdf) {
      await this.fileFindAll
        .internalExecute(urlPdf)
        .then((response: any) => {
          this.termsConditions = `data:${response.contentType};base64,${response.base64}`;
          this.fileName = response.name;
          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
          throw new Error(`${error}`);
        });
    }
  }

  findTypeIdentification() {
    this.isLoading = true;
    this.typeIdentificationFindAll
      .execute()
      .then((resp: any) => {
        this.isLoading = false;
        this.typeDocumentList = resp;
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  findWareHouses() {
    this.isLoading = true;
    this.warehouseFindAll
      .execute()
      .then((resp: any) => {
        this.isLoading = false;
        this.poBoxList = resp.filter((item: any) => {
          return item.virtualLocker;
        });
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  async findRole() {
    this.isLoading = true;

    await this.findRoleByIdNoUsers
      .execute(3)
      .then((res: any) => {
        if (!('error' in res) || !('message' in res)) {
          this.isLoading = false;
          this.payloadRole = res;
        }
      })
      .catch((err: any) => {
        this.isLoading = false;
        throw new Error(`${err}`);
      });
  }

  async onSubmit(event: any) {
    event.preventDefault();
    this.isLoading = true;

    try {
      // Create Entity
      this.formEntity.fullName = `${this.name} ${this.lastname}`;
      this.formEntity.code = 0;
      this.formEntity.createUser = true;
      this.formEntity.company = this.dataCompany.id;
      this.formEntity.address.country = this.formEntity.address.selectTerritorie.country;
      this.formEntity.address.countryName = this.formEntity.address.selectTerritorie.countryName;
      this.formEntity.address.state = this.formEntity.address.selectTerritorie.state;
      this.formEntity.address.stateName = this.formEntity.address.selectTerritorie.stateName;
      this.formEntity.address.city = this.formEntity.address.selectTerritorie.city;
      this.formEntity.address.cityName = this.formEntity.address.selectTerritorie.cityName;
      this.formEntity.typeCustomer = {
        type: 'CUSTOMER',
        name: 'CLIENTE',
        entities: []
      };

      const res: Entity = await this.createEntity.execute(this.formEntity);

      if ('error' in res || 'message' in res) {
        this.isLoading = false;
        return;
      }

      // Create User
      this.payloadUser.customerId = this.formEntity.id;
      this.payloadUser.customerCode = res.code;
      this.payloadUser.customerName = this.formEntity.fullName;
      this.payloadUser.customerEmail = this.formEntity.email;
      this.payloadUser.username = this.formEntity.email;
      this.payloadUser.password = this.formEntity.password;
      this.payloadUser.businessId = this.formEntity.company;
      this.payloadUser.businessName = this.dataCompany.businessName;
      this.payloadUser.entity = res;
      this.payloadUser.customerType = '01';
      this.payloadUser.configProfile = this.dataCompany.profile;
      this.payloadUser.roles = [
        {
          id: this.payloadRole.id,
          name: this.payloadRole.name,
          roleType: this.payloadRole.roleType,
          roleTypeName: this.payloadRole.roleTypeName,
          companyId: this.payloadRole.companyId,
          companyName: this.payloadRole.companyName,
          menu: [],
          user: [],
          type: ''
        }
      ];

      const resFinal = await this.createUser.execute(this.payloadUser);

      if ('error' in resFinal || 'message' in resFinal) {
        this.isLoading = false;
        return;
      }

      this.formEntity = new Entity();
      this.payloadUser = new User();
      this.$refs.form_registration.reset();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  async validateUserExists() {
    this.showUserExistence = false;
    if (this.formEntity.email) {
      try {
        this.isLoading = true;
        const response = await this.checkUserExistence.execute(this.formEntity.email);
        if (response) {
          this.showUserExistence = true;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        throw new Error(`${error}`);
      }
    }
  }
}
