import { Entity } from '../entity/entity/Entity';
import { Role } from '../role/Role';

export class User {
  businessId: string;
  businessName: string;
  configProfile: string;
  customerEmail: string;
  customerId: string;
  customerCode: number;
  customerName: string;
  customerType: string;
  enabled: boolean;
  logo: string;
  password: string;
  confirmPassword: string;
  registerDate: string;
  roles: Role[];
  username: string;
  entity: Entity | null;

  public constructor() {
    this.businessId = '';
    this.businessName = '';
    this.configProfile = '';
    this.customerEmail = '';
    this.customerId = '';
    this.customerCode = 0;
    this.customerName = '';
    this.customerType = '';
    this.enabled = true;
    this.logo = '';
    this.password = '';
    this.confirmPassword = '';
    this.registerDate = '';
    this.roles = [];
    this.username = '';
    this.entity = null;
  }
}
