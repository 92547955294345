import { Menu } from '@/settings/domain/menu/menu';
import { User } from '../user/User';

export class Role {
  id: number;
  name: string;
  roleType: number;
  roleTypeName: string;
  companyId: string;
  companyName: string;
  menu: Menu[];
  user: User[];
  type: any = {};

  public constructor() {
    this.id = 0;
    this.name = '';
    this.roleType = 0;
    this.roleTypeName = '';
    this.companyId = '';
    this.companyName = '';
    this.menu = [];
    this.user = [];
  }
}
